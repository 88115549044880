var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Layout", {
    staticClass: "i-layout"
  }, [!_vm.isMobile && !_vm.hideSider ? _c("Sider", {
    staticClass: "i-layout-sider",
    class: _vm.siderClasses,
    staticStyle: {
      height: "100%"
    },
    attrs: {
      width: _vm.menuSideWidth
    }
  }, [_c("i-menu-side", {
    attrs: {
      "hide-logo": _vm.isHeaderStick && _vm.headerFix && _vm.showHeader
    }
  })], 1) : _vm._e(), _c("Layout", {
    staticClass: "i-layout-inside",
    class: _vm.insideClasses
  }, [_c("transition", {
    attrs: {
      name: "fade-quick"
    }
  }, [_c("Header", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showHeader,
      expression: "showHeader"
    }, {
      name: "resize",
      rawName: "v-resize",
      value: _vm.handleHeaderWidthChange,
      expression: "handleHeaderWidthChange"
    }],
    staticClass: "i-layout-header",
    class: _vm.headerClasses,
    style: _vm.headerStyle
  }, [_vm.isMobile && _vm.showMobileLogo ? _c("i-header-logo") : _vm._e(), !_vm.isMobile && _vm.isHeaderStick && _vm.headerFix ? _c("i-header-logo") : _vm._e(), (_vm.isMobile || _vm.showSiderCollapse) && !_vm.hideSider ? _c("i-header-collapse", {
    on: {
      "on-toggle-drawer": _vm.handleToggleDrawer
    }
  }) : _vm._e(), !_vm.isMobile && _vm.showReload ? _c("i-header-reload", {
    on: {
      "on-reload": _vm.handleReload
    }
  }) : _vm._e(), _vm.headerMenu && !_vm.isMobile ? _c("i-menu-head", {
    ref: "menuHead"
  }) : _vm._e(), _vm.showBreadcrumb && !_vm.headerMenu && !_vm.isMobile ? _c("i-header-breadcrumb", {
    ref: "breadcrumb"
  }) : _vm._e(), _vm.showSearch && !_vm.headerMenu && !_vm.isMobile && !_vm.showBreadcrumb ? _c("i-header-search") : _vm._e(), _c("div", {
    staticClass: "i-layout-header-right"
  }, [_vm.showSearch && _vm.isMobile || _vm.showSearch && (_vm.headerMenu || _vm.showBreadcrumb) ? _c("i-header-search") : _vm._e(), _vm.headerMenu && _vm.isMobile ? _c("i-menu-head") : _vm._e(), _vm.isDesktop && _vm.showLog ? _c("i-header-log") : _vm._e(), _vm.isDesktop && _vm.showFullscreen ? _c("i-header-fullscreen") : _vm._e(), _vm.showNotice ? _c("i-header-notice") : _vm._e(), _c("i-header-user"), _vm.showI18n ? _c("i-header-i18n") : _vm._e(), _vm.enableSetting && !_vm.isMobile ? _c("i-header-setting") : _vm._e()], 1)], 1)], 1), _c("Content", {
    staticClass: "i-layout-content i-dp-fx i-fx-dc",
    class: _vm.contentClasses
  }, [_c("transition", {
    attrs: {
      name: "fade-quick"
    }
  }, [_vm.tabs ? _c("i-tabs", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showHeader,
      expression: "showHeader"
    }],
    on: {
      "on-reload": _vm.handleReload
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "i-layout-content-main i-dp-fx i-fx-dc i-fx-1"
  }, [_vm.headerFix && _vm.headerMenu && !_vm.isMobile ? _c("i-page-header") : _vm._e(), _c("div", {
    staticClass: "i-layout-content-main-card i-fx-1 i-scrollbar"
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.keepAlive
    }
  }, [_vm.loadRouter ? _c("router-view", {
    key: _vm.key,
    staticStyle: {
      height: "100%"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _vm.showCopyright ? _c("i-copyright") : _vm._e()], 1), _vm.isMobile && !_vm.hideSider ? _c("div", [_c("Drawer", {
    attrs: {
      placement: "left",
      closable: false,
      "class-name": _vm.drawerClasses
    },
    model: {
      value: _vm.showDrawer,
      callback: function callback($$v) {
        _vm.showDrawer = $$v;
      },
      expression: "showDrawer"
    }
  }, [_c("i-menu-side")], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };