import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import _toConsumableArray from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.for-each";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState } from 'vuex';
import { flattenSiderMenu } from '@/libs/system';
export default {
  name: 'iHeaderBreadcrumb',
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['showBreadcrumbIcon', 'menuCollapse'])), mapState('admin/menu', ['openNames', 'activePath', 'header', 'headerName', 'menuSider'])), {}, {
    siderMenuObject: function siderMenuObject() {
      var obj = {};
      this.allSiderMenu.forEach(function (item) {
        if ('path' in item) {
          obj[item.path] = item;
        }
      });
      return obj;
    },
    items: function items() {
      var _this = this;
      var items = _toConsumableArray(this.openNames);
      var newItems = [];
      items.forEach(function (i) {
        newItems.push(_this.siderMenuObject[i]);
      });
      return newItems;
    },
    // 第一级，默认是 menu/header.js 中的第一项
    topItem: function topItem() {
      var _this2 = this;
      return this.header.find(function (item) {
        return item.name === _this2.headerName;
      });
    },
    // 得到所有侧边菜单，并转为平级，查询图标及显示对应内容
    allSiderMenu: function allSiderMenu() {
      return flattenSiderMenu(this.menuSider, []);
    },
    breadcrumbList: function breadcrumbList() {
      // console.log('面包屑')
      // console.log('[路由] 顶部菜单 topItem:', this.topItem)
      // console.log('[路由] 左侧菜单当前选中父级 items:', this.items)
      // console.log('[路由] 左侧菜单 siderMenuObject:', this.siderMenuObject)
      // console.log('[路由] 当前选中 activePath:', this.activePath)
      // 详情页面不在菜单中显示，面包屑需要显示
      // 适配路由中的参数
      var detailPage = [];
      var _this$$route = this.$route,
        routePath = _this$$route.path,
        meta = _this$$route.meta,
        params = _this$$route.params,
        matched = _this$$route.matched;
      try {
        routePath = matched[matched.length - 1].path;
      } catch (error) {}
      if (meta && meta.showMenu === false && meta.activePath && meta.activePath !== routePath) {
        if (params && params.routeTitle) {
          detailPage.push(Object.assign({}, this.siderMenuObject[routePath], {
            title: params.routeTitle
          }));
        } else {
          detailPage.push(this.siderMenuObject[routePath]);
        }
      }
      // console.log('detailPage', detailPage)
      return [this.topItem].concat(_toConsumableArray(this.items), [this.siderMenuObject[this.activePath]], detailPage);
    },
    title: function title() {
      // let title = ''
      // console.log('[路由] 面包屑:', this.siderMenuObject)
      // console.log('[路由] 当前选中:', this.activePath)
      // if (this.siderMenuObject && this.activePath) {
      //     if (this.$route.meta && this.$route.meta.showMenu === false && this.activePath !== this.$route.path) {
      //         title = this.siderMenuObject[this.$route.path] ? this.siderMenuObject[this.$route.path].title : ''
      //     } else {
      //         title = this.siderMenuObject[this.activePath] ? this.siderMenuObject[this.activePath].title : ''
      //     }
      // }
      var _breadcrumbList = this.breadcrumbList.filter(function (item) {
        return item;
      });
      var lastRoute = _breadcrumbList[_breadcrumbList.length - 1];
      // console.log('[路由] 面包屑 title:', lastRoute.title)
      return lastRoute && lastRoute.title ? lastRoute.title : '';
    }
  }),
  data: function data() {
    return {};
  }
};